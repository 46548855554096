import React, { useState, useEffect } from "react";
import axios from "axios";
import { Wheel } from "react-custom-roulette";
import logoicon from "../../assets/img/logo-icon.png"
import logoicon6 from "../../assets/img/coloredbutton.png"
import { useTelegram } from '../TelegramContext'; // Adjust the path as needed

const originalData = [
  { option: "05" },
  { option: "10" },
  { option: "20" },
  { option: "30" },
  { option: "40" },
  { option: "50" },
  { option: "60" },
  { option: "00" },
  { option: "70" },
  { option: "80" },
  { option: "90" },
  { option: "100" },
];

const originalDummydata = [
  { option: "gh kshf fsfjdl" }

]



const Roulette = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [rouletteData, setRouletteData] = useState(originalData);
  const [addClassTo, setAddClassTo] = useState('two-tkCoins noClassto');
  const { telegramId } = useTelegram();
  const [loading, setLoading] = useState(true);
  const [earnGameSpin, setEarnGameSpin] = useState([]);
  const [alertForSpin, setAlertForSpin] = useState("");
  const [showAlertDa, setShowAlertDa] = useState(false);
  

  // let telegramId=989118640
  useEffect(() => {
    console.log("telegramId", telegramId)
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://api.tokame.network/gameloginspin/${telegramId}`);
        if (response.data.status) {
          setEarnGameSpin(response.data.result);
        } else {
          console.error('Error in API response status');
        }
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [telegramId]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.tokame.network/gameloginspin/${telegramId}`);
      if (response.data.status) {
        setEarnGameSpin(response.data.result);
      } else {
        console.error('Error in API response status');
      }
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };
  const postSpinData = async (prizeOption) => {
    try {
      const response = await axios.get(
        `https://api.tokame.network/storespindata/${telegramId}/${prizeOption}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('Data posted successfully:', response.data);
      console.log('iiiiiiiiiiiiiii', prizeOption)
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        // Request made but no response received
        console.error('Error request:', error.request);
      } else {
        // Something else happened in making the request
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };
  const handleSpinClick = async () => {
    await fetchData()
    if (earnGameSpin.earngamespin > 0) {
      const updatedAt = new Date(earnGameSpin.updatedAt);
      const currentTime = new Date();
      const timeDifference = currentTime - updatedAt;
      let hoursDifference = timeDifference / (1000 * 60 * 60);
      console.log("hoursDifference", hoursDifference);

      if (hoursDifference > 24) {
        const newPrizeNumber = Math.floor(Math.random() * originalData.length);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
        setAddClassTo('addClsto');
        const prizeOption = originalData[newPrizeNumber].option;
        await postSpinData(prizeOption);
        //  window.location.reload();
        console.log("%%%%%%%%%%%%%%", prizeOption);
      } else {
        console.log('The time difference is greater than 24 hours. The function will not execute.');
       const setTimings = `${24 - hoursDifference.toFixed(2)}`       
        setAlertForSpin(`You can only spin once every 24 hours. Please try again ${setTimings} hours later`)
        setShowAlertDa(true)
       // alert(`ggggggg`);
      }
    } else {
      const newPrizeNumber = Math.floor(Math.random() * originalData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
      setAddClassTo('addClsto');
      const prizeOption = originalData[newPrizeNumber].option;
      await postSpinData(prizeOption);
      // window.location.reload();

      console.log("%%%%%%%%%%%%%%", prizeOption);
    }
  };

  const handleOkBtn = () => {
    setShowAlertDa(false) 
  }


  return (
    <>
      <div align="center" className="roulette-container">
      {showAlertDa ?
         <div className="soanDia-Container">
          <span className="soanDia">{alertForSpin}
         <button onClick={handleOkBtn}>Ok</button>
         </span>
           
         </div>
      :
   <></>
      }


     

        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={rouletteData}
          spinDuration={[0.2]}
          outerBorderColor={["#f2f2f2"]}
          outerBorderWidth={[10]}
          innerBorderColor={["#f2f2f2"]}
          radiusLineColor={["#dedede"]}
          radiusLineWidth={[1]}
          fontSize={30}
          textColors={["#000000"]}
          backgroundColors={[
            "#ff6600",
            "#f3ba2f",
            "#ff6600",
            "#f3ba2f",
            "#ff6600",
            "#f3ba2f",
            "#ff6600",
            "#f3ba2f",
            "#ff6600",
            "#f3ba2f",
            "#ff6600",
            "#f3ba2f"
          ]}
          onStopSpinning={() => {
            setMustSpin(false);
            console.log(originalData[prizeNumber]);
          }}
        />
        <button className="button roulette-button" onClick={handleSpinClick}>
          <img src={logoicon} alt="logo" />
        </button>
      </div>
      {/* <br />
      <button className="SpinButton" onClick={handleSpinClick}>SPIN</button> */}
      <div className="winNumber">
        {!mustSpin ? (
          <ul>
            {/* <li className={addClassTo}> </li> */}
            <li className={addClassTo}>
              {originalData[prizeNumber].option === "00"
                ? <span><span>"Oops" better Luck next time</span><span>{originalData[prizeNumber].option} TOK Coin</span> </span>
                : <span><span>Congratulations!</span> <span>You have won</span><span>{originalData[prizeNumber].option} TOK Coin</span></span>}
            </li>

            <li className="two-tkCoins">
              <button className="logoicon6n" onClick={handleSpinClick}>
                <img className="logoicon6" src={logoicon6} alt="logo" />
              </button>
            </li>
          </ul>
        ) : (
          <span>SPINNING...</span>
        )}
      </div>
    </>
  );
};

export default Roulette;
