import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TOKcoin2 from "../../assets/img/profile-icon.png";
import setting from "../../assets/img/setting.png";
import loader from "../../assets/img/loader.gif";
import binance from "../../assets/img/binance.png";
import checkd from "../../assets/img/checkd.png"
import SpinLeatherBoardList from "../tokSpinList/SpinLeatherBoardList";
import './stylePopup.css';
import bottomSide from "../../assets/img/bottomSide.png"
import topSide from "../../assets/img/topSide.png"

import { useTelegram } from '../TelegramContext'; // Adjust the path as needed

const HeaderMenues = () => {
  const [loading, setLoading] = useState(true);

  const [loadingM, setLoadingM] = useState(true);

  const [exchanges, setExchanges] = useState([]);
  const [multiplier, setMultiplier] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const { telegramId } = useTelegram();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedExchangeUrl, setSelectedExchangeUrl] = useState(binance);

  const [selectedIndex, setSelectedIndex] = useState(null);
  // let telegramId=989118640
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://api.tokame.network/getExchanges`);
        if (response.data.status) {
          setExchanges(response.data.result);
        } else {
          console.error('Error in API response status');
        }
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [width, setWidth] = useState('0%');
  const [unit, setUnit] = useState('px'); // Default unit
  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(`https://api.tokame.network/gameloginspin/${telegramId}`);
        if (response.data.status) {
          setMultiplier(response.data.result);
          const multiplier2 = response.data.result.boster
          const multiplier = multiplier2 * 2
          setWidth(multiplier);
          if (multiplier && unit) {
            // Determine if the unit is percentage or other
            if (unit === 'px') {
              setWidth(`${multiplier}%`);
            } else if (unit === 'px') {
              setWidth(`${width}%`);
            } else {
              // Handle other units or fallback
              console.warn(`Unsupported unit: ${unit}`);
              setWidth('50%'); // Fallback width
            }
            setUnit(multiplier.unit);
          }
          setErrorMessage(''); // Clear any previous error message
        } else {
          console.error('Error in API response status');
          setErrorMessage(response.data.result); // Set the error message from the response
        }

        if (!response.data.result) {
          setLoadingM(true);

        } else {
          setLoadingM(false);
        }


      } catch (error) {
        console.error('Error fetching data', error);
        setErrorMessage('Error fetching data'); // Set a generic error message
      } finally {
        setLoading("999999999999999999");
      }
    };

    fetchData();
  }, [telegramId]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleClickOutside = (event) => {
    if (isChecked && !event.target.closest('.toggle')) {
      setIsChecked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isChecked]);

  const handleExchangeClick = (exchangeUrl, index) => {
    setSelectedExchangeUrl(exchangeUrl);
    setIsChecked(false); // Close the list
    setSelectedIndex(index);
    document.getElementById("popup-1").checked = false; // Uncheck the popup checkbox
  };



  const [isActive, setIsActive] = useState(true);
  const handleDrpRankClick = () => {
    setIsActive(!isActive);
  };

  const [userDataStatus, setUserDataStatus] = useState([]);
  const fetchRankData = async () => {
    try {
      const response = await axios.get(`https://api.tokame.network/getleaderboard`);
      if (response.data.status) {
        const rankData = response.data.result;
        setUserDataStatus(rankData);
        console.error('xxxxxxxxxxxxxxxxxxxxx', rankData);
      } else {
        console.error('wwwwwwwwwwwwwwwwwwwwww');
      }
    } catch (error) {
      console.error('00000000000000000000', error);
    }
  };

  useEffect(() => {
    fetchRankData();
  }, []);




  return (
    <div className='container'>
      <div className='containerBox'>
        {errorMessage && <p>{errorMessage}</p>}
        <div className='row'>
          <div className='col-md-12'>
            <div className='HeaderMenues-box'>
              <span><img className='TOKcoin2' src={TOKcoin2} alt='TOKcoin2' /></span>
              <span>
                {loadingM ?
                  <>
                    <div class="loader">
                      <div class="loader-inner ball-pulse">
                    
                      <div className='pulses'></div>
                      <div className='pulses'></div>
                      <div className='pulses'></div>
                      </div>
                    </div>
                  </>
                  :
                  <> {multiplier.username} </>
                }
              </span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6' style={{ padding: '0' }}>
            <div className='HeaderMenues-box'>
              <p>Multiplier {multiplier.boster}x</p>
              <span className='biFxd'>
                
                  {loadingM ?
                    <> <div class="loader">
                    <div class="loader-inner ball-pulse">
                      <div className='pulses'></div>
                      <div className='pulses'></div>
                      <div className='pulses'></div>
                    </div>
                  </div> </>
                    :
                    <span className='dixBlkid' style={{ width: width }}>  </span>
                  }
               
              </span>
            </div>
          </div>
          <div className='col-6' style={{ padding: '0' }}>
            <div className='HeadeueSlides'>
              <div className='dixBlk'>
                <span><label className='labsIdx' htmlFor="popup-1">
                  <img className='TOKcoin4' src={selectedExchangeUrl} alt='exchange' /></label></span>
              </div>
              <div className='dWxsign'>

                <div className='dixBlk dOmBoxify'>

                  <ul className='menu9'>
                    <li class="dropdown dropdown-6" onClick={handleDrpRankClick}>
                      <span className='BXaDfg'><b>Rank {multiplier.rank}</b></span>
                      <span className='BXaDfg'>
                        {isActive ?
                          <span>
                            <img className='bottomSideAr' src={bottomSide} alt='bottomSide' />
                          </span>
                          :
                          <span>
                            <img className='bottomSideAr' src={topSide} alt='topSide' />
                          </span>
                        }
                      </span>
                      <ul className={isActive ? 'dropdown_menu dropdown_menu-6' : 'dropdown_menu dropdown_menu--animated dropdown_menu-6'} >
                        {userDataStatus.map(user => (
                          <li key={user.id}><span>{user.username}</span> <span>{user.earngamespin}</span></li>
                        ))}

                      </ul>
                    </li>

                  </ul>
                </div>
                {/* <div className='dixBlk' style={{ borderLeft: '1px solid #fff', display: "none" }}>
                  <span><img className='TOKcoin4' src={setting} alt='setting' /></span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='rowBottom'>
          <div className='earngConatiner'>
            <span>TOK 
            {loadingM ?
                    <> <div class="loader" style={{position: "absolute",top: "2px", right: "-35px"}}>
                    <div class="loader-inner ball-pulse">
                      <div className='pulses'></div>
                      <div className='pulses'></div>
                      <div className='pulses'></div>
                    </div>
                  </div> </>
                    :
                    <>
                      <b style={{marginRight: "5px",fontWeight:"normal"}}> {multiplier.earngamespin}</b>
                    </>
                  }
              
              </span>
          </div>
        </div>
      </div>

      <input type="checkbox" className="vcms__popup-check" name="popup-1" id="popup-1" />
      <div className="vcms__popup">
        <div className="inner">
          <div className="header">
            <div className='hedone'>Choose Exchange</div>
            <div className='hedone'><label className="closew" htmlFor="popup-1">X</label></div>
          </div>
          <div className="exchngeList">
            <ul>
              {exchanges.map((exchange, index) => (
                <li key={index} onClick={() => handleExchangeClick(exchange.url, index)}>
                  <span><img src={exchange.url} /></span> <span>{exchange.name}</span>

                  {selectedIndex === index && (
                    <span className='checkdSign'>
                      <img src={checkd} alt="checked" />
                    </span>
                  )}

                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenues;
