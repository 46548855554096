import React, { useState, useEffect, useRef } from 'react';
import invite from "../../assets/img/invite.png"
import gift from "../../assets/img/reward.png"
import copy2 from "../../assets/img/copy-2.png"
import { useTelegram } from '../TelegramContext'; // Adjust the path as needed
import axios from "axios";

const ReferScreen = () => {
   const { telegramId } = useTelegram();
  const [earnGameSpin, setEarnGameSpin] = useState([])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () =>{
      setLoading(true);
      try {
        const response = await axios.get(`https://api.tokame.network/gameloginspin/${telegramId}`);
        if (response.data.status) {
          console.log("earnGameSpin.referrallink",response.data.result
          )

          setEarnGameSpin(response.data.result);

        } else {
          console.error('Error in API response status');
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };


    fetchData();
  }, [telegramId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(earnGameSpin.referrallink)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy the link: ', err);
      });
  };

  const shareOnTelegram = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(earnGameSpin.referrallink)}&text=Check this out!`;
    window.location.href = telegramUrl;
  };

  return (
    <>
      <div className='container mrGinBottom'>
        <div className='row' style={{ justifyContent: "center" }}>

          <div className='wraper-mineScreen5'>
            <h3>Invite Friends !</h3>
            <span>You and your friend will receive bonuses</span>
          </div>
          <div className='referNumber'>
            <img src={invite} alt="refer grey" />
          </div>
          <div className="playNumber">
            <ul>
              <li><span>
                <img className='playBubble' src={gift} alt="play" />
              </span>
                <span><b>Invite A friend</b><b>Get 1000 TOK points</b></span></li>
              <li>
                <span><img className='playBubble' src={gift} alt="play" /></span>
                <span><b>Invite 3 of your friends</b><b>Get 4000 TOK points</b></span></li>
            </ul>
          </div>
          <div className="shareLinks">
      <div className='linksBtns'>
        <button className='shareLinksbtn' onClick={shareOnTelegram}>
          Share the Link
        </button>
      </div>
      <div className='linksBtns Sherdsx'>
        <img className='playBubble' src={copy2} alt="play" onClick={copyToClipboard} />
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default ReferScreen;


