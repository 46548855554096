// TelegramContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const TelegramContext = createContext();

export const TelegramProvider = ({ children }) => {
  const [telegramId, setTelegramId] = useState(null);

  useEffect(() => {
    const initTelegram = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        tg.ready();
        const user = tg.initDataUnsafe?.user;

        if (user) {
         setTelegramId(user.id);
 // setTelegramId(989118640);
        } else {
          console.log("No user data available in tg.initDataUnsafe");
        }
      } else {
        console.log("Telegram WebApp is not available on window object");
      }
    };

   initTelegram();
  }, []);

  return (
    <TelegramContext.Provider value={{ telegramId }}>
      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => {
  return useContext(TelegramContext);
};
