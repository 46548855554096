import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../src/container/homeFolder/HomePage';
import MineScreen from '../src/container/mineFolder/MineScreen';
import PlayScreen from '../src/container/playFolder/PlayScreen';
import ReferScreen from '../src/container/referFolder/ReferScreen';
import TaskScreen from '../src/container/taskFolder/TaskScreen';
import BottomNavigationBar from '../src/container/bottomNavigationBar/BottomNavigationBar';
import HeaderMenues from '../src/container/headerMenues/HeaderMenues';
import HeaderTopMenue from "../src/container/headerMenues/HeaderTopMenue";
import { TelegramProvider } from './container/TelegramContext';
import './App.css';
function App() {

  // const handleContextMenu = (e) => {
  //   e.preventDefault();
  // };
  // useEffect(() => {
  //   // Disable zooming
  //   const metaViewport = document.querySelector('meta[name="viewport"]');
  //   if (metaViewport) {
  //     metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
  //   }
  // }, []);
  const preventZoom = (event) => {
    event.preventDefault();
    // Additional logic can be added here if needed
  };

  return (
    <div onTouchStart={preventZoom}>
    <TelegramProvider>
      <div className='container'>
        <HeaderTopMenue />
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />  
          <Route path="/mine" element={<MineScreen />} />
          <Route path="/play" element={<PlayScreen />} />
          <Route path="/refer" element={<ReferScreen />} />
          <Route path="/task" element={<TaskScreen />} /> 
        </Routes>
        <BottomNavigationBar />
      </Router>
    </TelegramProvider>
    </div>
  );
}

export default App;
